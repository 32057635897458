export const Images = {
  twitter: require("../assets/images/icn-socialmedia-twitter.svg"),
  facebook: require("../assets/images/icn-socialmedia-facebook.svg"),
  youtube: require("../assets/images/icn-socialmedia-youtube.svg"),
  instagram: require("../assets/images/icn-socialmedia-instagram.svg"),
  vimeo: require("../assets/images/icn-socialmedia-vimeo.svg"),
  heroBanner: require("../assets/images/hero-banner.svg"),
  PLHeaderLogo: require("../assets/images/header logo.svg"),
  heroBackgroundImage: require("../assets/images/hero background image.png"),
  virtualCareerImage: require("../assets/images/virtual career.svg"),
  bestOfTheBest: require("../assets/images/bestOfTheBest.svg"),
  freeToPlay: require("../assets/images/freeToPlay.svg"),
  richFeatures: require("../assets/images/richfeatures.svg"),
  UHL: require("../assets/images/UHL.svg"),
  UHL3: require("../assets/images/uhl-03.png"),
  thankYouYouAreOn: require("../assets/images/thank you youre on.svg"),
  statsLogo: require("../assets/images/stats-logo.svg"),
  statsLogo2: require("../assets/images/stats-logo-2.svg"),
  unexpectedError: require("../assets/images/unexpected error.svg"),
  rinkBackground: require("../assets/images/rink background.png"),
  leaguesBackground: require("../assets/images/leagues background.png"),
  crossIcon: require("../assets/images/cross.svg"),
  backArrowIcon: require("../assets/images/back-arrow.svg"),
  uploadIcon: require("../assets/images/upload-icon.svg"),
  profileBackgroundGradient: require("../assets/images/profile background gradient.png"),
  crowdBackgroundImage: require("../assets/images/crowd background image.png"),
  accountIcon: require("../assets/images/account.svg"),
  selectedAccountIcon: require("../assets/images/Selected-account.svg"),
  tickIcon: require("../assets/images/tick.svg"),

  profileIcon: require("../assets/images/profile.svg"),
  defautAvatar: require("../assets/images/avatar.png"),
  selectedProfileIcon: require("../assets/images/Selected-profile.svg"),

  leaguesIcon: require("../assets/images/leagues.svg"),
  selectedLeaguesIcon: require("../assets/images/Selected-leagues.svg"),

  securityIcon: require("../assets/images/security.svg"),
  selectedSecurityIcon: require("../assets/images/Selected-security.svg"),

  myTeamsIcon: require("../assets/images/my-teams.svg"),
  selectedMyTeamIcon: require("../assets/images/selected-my-teams.svg"),

  logoutIcon: require("../assets/images/logout.svg"),
  topLeftCorner: require("../assets/images/topLeftCorner.svg"),
  topRightCorner: require("../assets/images/topRightCorner.svg"),

  hammerIcon: require("../assets/images/hammer.svg"),
  magnifyingGlassIcon: require("../assets/images/magnifyingGlass.svg"),
  magnifyingGlassActiveIcon: require("../assets/images/magnifyingGlassActive.svg"),
  userAccountIcon: require("../assets/images/userAccount.svg"),
  userAccountActive: require("../assets/images/userAccountActive.svg"),
  viewEyeIcon: require("../assets/images/view-eye.svg"),

  managementTeamsIcon: require("../assets/images/managementTeam.svg"),
  selectedManagementTeamsIcon: require("../assets/images/SelectedManagementTeam.svg"),
  managementLeagueIcon: require("../assets/images/managementLeague.svg"),
  selectedManagementLeagueIcon: require("../assets/images/SelectedManagementLeague.svg"),
  selectedManagementLeagueTeamsIcon: require("../assets/images/SelectedManagementLeague.svg"),
  vs: require("../assets/images/vs.svg"),
  smallCrossIcon: require("../assets/images/small-cross.svg"),
  checkIcon: require("../assets/images/check.svg"),
  unCheckIcon: require("../assets/images/unCheck.svg"),
  settingIcon: require("../assets/images/setting.svg"),
  editLeagueIcon: require("../assets/images/editLeagueIcon.svg"),
  createSeasonIcon: require("../assets/images/createSeason.svg"),
  manageSeasonIcon: require("../assets/images/manageSeason.svg"),
  editPencilIcon: require("../assets/images/editPencil.svg"),
  octagon: require("../assets/images/octagon.svg"),
  notifyIcon: require("../assets/images/notify.svg"),
  teamBackgroundColorShape: (color = "#7adfff") => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="150.225"
      height="92.001"
      viewBox="0 0 150.225 92.001"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            id="Subtraction_4"
            data-name="Subtraction 4"
            d="M152,95H1.775L29,49,1.775,3H152V95Z"
            transform="translate(-1282 16317.001)"
            fill="#88e2ff"
            stroke="#707070"
            strokeWidth={1}
            opacity="0.31"
          />
        </clipPath>
        <filter
          id="Union_7"
          x="-1491.775"
          y={-15}
          width={1662}
          height="121.999"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx={5} input="SourceAlpha" />
          <feGaussianBlur stdDeviation={5} result="blur" />
          <feFlood floodOpacity="0.102" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Mask_Group_2"
        data-name="Mask Group 2"
        transform="translate(1280.225 -16320.001)"
        clipPath="url(#clip-path)"
      >
        <g
          id="Group_1067"
          data-name="Group 1067"
          transform="translate(-3241 16062)"
        >
          <g
            transform="matrix(1, 0, 0, 1, 1960.78, 258)"
            filter="url(#Union_7)"
          >
            <path
              id="Union_7-2"
              data-name="Union 7"
              d="M0,0H1632V92H0Z"
              transform="translate(-1481.78 0)"
              fill={color}
            />
          </g>
        </g>
        <path
          id="Union_20"
          data-name="Union 20"
          d="M880.258,92H0V0H905.732l26.539,46L905.732,92Z"
          transform="translate(-2176.771 16320)"
          opacity="0.1"
        />
        <path
          id="Union_21"
          data-name="Union 21"
          d="M880.259,92H-576V0H905.731L932.27,46,905.731,92Z"
          transform="translate(-2185.771 16320)"
          fill="#56f"
        />
      </g>
    </svg>
  ),
  PlayerProfileImageRectangle: ({ imageUrl = "" }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="76.154"
      height={70}
      viewBox="0 0 76.154 70"
    >
      <defs>
        <filter
          id="black-man-posing"
          x={0}
          y={0}
          width="76.154"
          height={70}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx={5} input="SourceAlpha" />
          <feGaussianBlur stdDeviation={5} result="blur" />
          <feFlood floodOpacity="0.102" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
        <pattern
          id="pattern"
          preserveAspectRatio="xMidYMid slice"
          width="100%"
          height="100%"
          viewBox="0 0 512 512"
        >
          <image width={512} height={512} xlinkHref={`${imageUrl}`} />
        </pattern>
      </defs>
      <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#black-man-posing)">
        <path
          id="black-man-posing-2"
          data-name="black-man-posing"
          d="M34.615,0,46.154,20,34.615,40H11.538L0,20,11.538,0Z"
          transform="translate(10 15)"
          fill="#ffbe26"
        />
      </g>
      <path
        id="_5309"
        data-name={5309}
        d="M34.615,0,46.154,20,34.615,40H11.538L0,20,11.538,0Z"
        transform="translate(10 15)"
        fill="url(#pattern)"
      />
    </svg>
  ),
  playerProfileOctagonalShape: () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="76.154"
      height="70"
      viewBox="0 0 76.154 70"
    >
      <defs>
        <filter
          id="black-man-posing"
          x={0}
          y={0}
          width="76.154"
          height="70"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx={5} input="SourceAlpha" />
          <feGaussianBlur stdDeviation={5} result="blur" />
          <feFlood floodOpacity="0.102" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
        <pattern
          id="pattern"
          preserveAspectRatio="xMidYMid slice"
          width="100%"
          height="100%"
          viewBox="0 0 512 512"
        >
          <image width={512} height={512} />
        </pattern>
      </defs>
      <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#black-man-posing)">
        <path
          id="black-man-posing-2"
          data-name="black-man-posing"
          d="M34.615,0,46.154,20,34.615,40H11.538L0,20,11.538,0Z"
          transform="translate(10 15)"
          stroke="#ffbe26"
          fill="transparent"
          stroke-width="3"
        />
      </g>
      <path
        id="_5309"
        data-name={5309}
        d="M34.615,0,46.154,20,34.615,40H11.538L0,20,11.538,0Z"
        transform="translate(10 15)"
        fill="url(#pattern)"
      />
    </svg>
  ),
  playerProfilePictureShape: () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="76.154"
      height={70}
      viewBox="0 0 76.154 70"
    >
      <defs>
        <filter
          id={"black-man-posing"}
          x={0}
          y={0}
          width="76.154"
          height={70}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx={5} input="SourceAlpha" />
          <feGaussianBlur stdDeviation={5} result="blur" />
          <feFlood floodOpacity="0.102" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
        <pattern
          id="pattern"
          preserveAspectRatio="xMidYMid slice"
          width="100%"
          height="100%"
          viewBox="0 0 512 512"
        >
          <image width={512} height={512} xlinkHref={""} />
        </pattern>
      </defs>
      <g transform="matrix(1, 0, 0, 1, 0, 0)" filter={"url(#black-man-posing)"}>
        <path
          id={"black-man-posing-2"}
          data-name={"black-man-posing"}
          d="M34.615,0,46.154,20,34.615,40H11.538L0,20,11.538,0Z"
          transform="translate(10 15)"
          fill="#ffbe26"
        />
      </g>
      <path
        id="_5309"
        data-name={5309}
        d="M34.615,0,46.154,20,34.615,40H11.538L0,20,11.538,0Z"
        transform="translate(10 15)"
        fill="url(#pattern)"
      />
    </svg>
  ),
};
