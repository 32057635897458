import { GET_NEWS } from "store/actionTypes";

const initialState = {
  plgNews: {
    isLoading: true,
    news: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_NEWS: {
      return {
        ...state,
        plgNews: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
