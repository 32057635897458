import { Images } from "config/images";
import React from "react";

const { PLHeaderLogo } = Images;
const SpinnerComponent = () => {
  return (
    <div
      role="status"
      className="bg-c_282828 h-screen flex items-center justify-center"
    >
      <div className="flex items-center flex-col gap-5">
        <img
          className="w-20"
          src={PLHeaderLogo.default}
          alt="logo"
          draggable={false}
        />
        <div className="w-32 h-32 border-t-4 border-t-c_FFD13A border-r-4 border-r-transparent rounded-full border-dotted animate-spin shadow"></div>
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export default SpinnerComponent;
