import { GET_GUEST_USER_LEAGUES, GET_USER_LEAGUES } from "store/actionTypes";

const initialState = {
  userLeagues: [],
  guestUserLeagues: {
    isLoading: true,
    leagues: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_LEAGUES: {
      return {
        ...state,
        userLeagues: action.payload,
      };
    }
    case GET_GUEST_USER_LEAGUES: {
      return {
        ...state,
        guestUserLeagues: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
