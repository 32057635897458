import { combineReducers } from "redux";
import home from "./home";
import leagues from "./leagues";
import news from "./news";
import teams from "./teams";

export default combineReducers({
  Leagues: leagues,
  Teams: teams,
  Home: home,
  News: news,
});
