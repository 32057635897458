import SpinnerComponent from "components/spinner/spinnerComponent";
import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

const userData = JSON.parse(localStorage.getItem("data")) || {};
const { isLeagueManager = 0, isTeamManager = 0 } = userData;

const AppRoutes = () => {
  const routes = [
    {
      id: "1",
      path: "/",
      component: lazy(() => import("../screens/home")),
      nestedPaths: [],
    },
    {
      id: "2",
      path: "/sign-up",
      component: lazy(() => import("../screens/signUp")),
      nestedPaths: [],
    },
    {
      id: "3",
      path: "/login",
      component: lazy(() => import("../screens/login")),
      nestedPaths: [],
    },
    {
      id: "4",
      path: "/email-verified",
      component: lazy(() => import("../screens/emailVerifiedScreen")),
      nestedPaths: [],
    },
    {
      id: "5",
      path: "/forgot-password",
      component: lazy(() => import("../screens/forgotPassword")),
      nestedPaths: [],
    },
    {
      id: "6",
      path: "/forgot-password-otp",
      component: lazy(() => import("../screens/forgotPasswordOtp")),
      nestedPaths: [],
    },
    {
      id: "7",
      path: "/change-password",
      component: lazy(() => import("../screens/changePassword")),
      nestedPaths: [],
    },

    {
      id: "8",
      path: "/leagues",
      component: lazy(() => import("../screens/leagues2")),
      nestedPaths: [],
    },
    {
      id: "9",
      path: "/change-password-success",
      component: lazy(() => import("../screens/changePasswordSuccess")),
      nestedPaths: [],
    },
    {
      id: "9",
      path: "/dashboard",
      component: lazy(() => import("../screens/dashboard")),
      nestedPaths: [
        {
          id: "1",
          path: "account",
          component: lazy(() => import("../screens/userAccount")),
        },
        {
          id: "2",
          path: "profile",
          component: lazy(() => import("../screens/userAccount")),
        },
        {
          id: "3",
          path: "leagues",
          component: lazy(() => import("../screens/userLeagues")),
        },
        {
          id: "4",
          path: "security",
          component: lazy(() => import("../screens/userSecurity")),
        },
        {
          id: "5",
          path: "my-team",
          component: lazy(() => import("../screens/userLeagues")),
        },
        ...(isLeagueManager > 0
          ? [
              {
                id: "6",
                path: "team-manager-leagues",
                component: lazy(() => import("../screens/teamManagerLeagues")),
              },
            ]
          : []),
        ...(isTeamManager > 0
          ? [
              {
                id: "7",
                path: "team-manager-teams",
                component: lazy(() => import("../screens/ManagerTeams")),
              },
            ]
          : []),

        {
          id: "8",
          path: "create-league",
          component: lazy(() => import("../screens/createLeague")),
        },
        {
          id: "9",
          path: "create-season",
          component: lazy(() => import("../screens/createSeason")),
        },
        {
          id: "10",
          path: "my-teams",
          component: lazy(() => import("../screens/myTeam")),
        },
        {
          id: "11",
          path: "manage-season/:ID",
          component: lazy(() => import("../screens/manageSeason")),
        },
        {
          id: "12",
          path: "manage-season-auction-process/:ID",
          component: lazy(() =>
            import("../screens/manageSeason/ManageSeasonActionProcess")
          ),
        },
        {
          id: "13",
          path: "manage-season-schedule-league/:ID",
          component: lazy(() =>
            import("../screens/manageSeason/SeasonScheduleLeague")
          ),
        },
        {
          id: "14",
          path: "manager-team-details/:ID",
          component: lazy(() => import("../screens/ManagerTeamDetails")),
        },
        {
          id: "15",
          path: "assign-player-positions",
          component: lazy(() => import("../screens/AssignPlayerPositions")),
        },
        {
          id: "16",
          path: "league-players",
          component: lazy(() => import("../screens/leaguePlayers")),
        },
      ],
    },
    {
      id: "6",
      path: "auction-process/:id",
      component: lazy(() => import("../screens/auctionProcess")),
      nestedPaths: [],
    },

    {
      id: "7",
      path: "player-profile",
      component: lazy(() => import("../screens/playerProfile")),
      nestedPaths: [],
    },
    {
      id: "8",
      path: "auction-process-team-manager",
      component: lazy(() => import("../screens/draftProcessTeamManager")),
      nestedPaths: [],
    },
    {
      id: "9",
      path: "games-stats/:id",
      component: lazy(() => import("../screens/gamesStats")),
      nestedPaths: [],
    },
    {
      id: "10",
      path: "team-profile",
      component: lazy(() => import("../screens/teamProfile")),
      nestedPaths: [],
    },
    {
      id: "12",
      path: "news",
      component: lazy(() => import("../screens/news")),
      nestedPaths: [],
    },
    {
      id:"13",
      path: "leagues-details/:leagueId",
      component: lazy(() => import("../screens/leaguesDetails")),
      nestedPaths: [],
    },

    {
      id: "11",
      path: "*",
      component: lazy(() => import("../screens/noRouteFound404")),
    },
  ];

  return (
    <>
      <Routes>
        {routes?.map((item) => (
          <Route
            key={item?.id}
            path={item?.path}
            element={
              <Suspense fallback={<SpinnerComponent />}>
                <item.component />
              </Suspense>
            }
          >
            {item?.nestedPaths?.length &&
              item?.nestedPaths?.map((subItem) => (
                <Route
                  key={subItem?.id}
                  path={subItem?.path}
                  element={
                    <Suspense>
                      <subItem.component />
                    </Suspense>
                  }
                />
              ))}
          </Route>
        ))}
      </Routes>
    </>
  );
};

export default AppRoutes;
