import { GET_HOME_DATA } from "store/actionTypes";

const initialState = {
  home: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_HOME_DATA: {
      return {
        ...state,
        home: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
