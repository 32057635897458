import { GET_USER_TEAMS } from "store/actionTypes";

const initialState = {
  userTeams: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_TEAMS: {
      return {
        ...state,
        userTeams: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
